import React, { useEffect } from 'react'
import homestay from '../images/Homestay.jpg'

const AboutUs = () => {
    //scroll to top on page refresh
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <div className='container mb-5'>
            <h1 className='my-4 text-center'>About Us</h1>
            <p className='text-center'>Do you love to be with nature, away from hectic day to day life and relax? <br /> Would you like to enjoy your time with family and friends?</p>
            <hr className='my-5' />


            <div className="grid my-5">
                <div className="g-col-12 g-col-md-6 mx-3">
                    <h4 style={{ "lineHeight": "1.75", "color": "green" }}>Welcome to Pragati Homestay - <br /> Nagarakodi,Shamemane, Sirsi.</h4>
                </div>
                <div className="g-col-12 g-col-md-6 mx-3">
                    <p>Pragati Homestay offers the unexplored beauty of nature with the traditional hospitality of Malnad (malenādu). Surrounded by thick forest and various other natural treasures which have become part of local life since generations, Pragati Homestay is a perfect destination for creating a fun-filled and joyous memory in your life.</p>
                </div>
            </div>

            <div className="grid card border-0 mb-5">
                <div className="g-col-12 g-col-md-6 mx-3">
                    <img className='card-img'
                        src={homestay} alt="Front view of Homestay"/>
                </div>
                <div className="g-col-12 g-col-md-6 mx-3">
                    <h4 className='text-center greenText'>Front view of Homestay</h4>
                    <p className='mb-2'>Pragati Homestay offers the unexplored beauty of nature with the traditional hospitality of Malnad (malenadu)..</p>
                    <p className='mb-2'>Surrounded by thick forest and various other natural treasures which have become part of local life since generations, Pragati Homestay is a perfect destination for creating a fun filled and joyous memory in your life.</p>
                    <p className='mb-2'>Pragati Homestay, it is where you relax, recharge and rejuvenates yourself for a fresh beginning. Ideal place for couples and the entire family to create beautiful everlasting memories.</p>
                    <p className='mb-2'>Surrounded by lots of greenery and fresh natural treasures your stay with us will be sensational and equally thrilling experience.</p>
                </div>
            </div>
            <hr />
        </div>
    )
}

export default AboutUs