import React, {useEffect}from 'react'

const Gallery = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    //import all inamges from a folder 
    function importAll(r) {
        let images = [];
        // eslint-disable-next-line
        r.keys().map((item, index) => { images.push(r(item)); });
        return images;
    }
    const images = importAll(require.context('../gallery', false, /\.(png|jpe?g|JPG|svg)$/));

    return (
        <div className='container mb-5'>
            <h1 className='my-4 text-center'>GALLERY</h1>
            <hr className='my-5' />

            <div className="container grid my-5">
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[6]} className="card-img-top" alt="Front view of homestay" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[7]} className="card-img-top" alt="side view of homestay" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[8]} className="card-img-top" alt="back view of homestay" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[9]} className="card-img-top" alt="Front view of homestay" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[10]} className="card-img-top" alt="Front view of homestay" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[11]} className="card-img-top" alt="Room with good hospitality" />
                    </div>
                </div>
               
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[13]} className="card-img-top" alt="Room with good hospitality" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[14]} className="card-img-top" alt="Room with good hospitality" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[18]} className="card-img-top" alt="Room with good hospitality" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[15]} className="card-img-top" alt="nice view of hall" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[12]} className="card-img-top" alt="Room with good hospitality" />
                    </div>
                </div>
               
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[16]} className="card-img-top" alt="nice view of hall" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[17]} className="card-img-top" alt="nice view of hall" />
                    </div>
                </div>
                
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[4]} className="card-img-top" alt="Enjoying in fresh water lake" />
                    </div>
                </div>

                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[3]} className="card-img-top" alt="kids enjoying in playground" />
                    </div>
                </div>

                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[0]} className="card-img-top" alt="blakeeautiful view of lake" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[1]} className="card-img-top" alt="blakeeautiful view of lake" />
                    </div>
                </div>
                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[5]} className="card-img-top" alt="blakeeautiful view of lake" />
                    </div>
                </div>

                <div className="g-col-md-4 g-col-12">
                    <div className="card border-0">
                        <img src={images[2]} className="card-img-top" alt="Drone view of beatiful nature" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gallery