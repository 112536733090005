import React,{useEffect} from 'react'

const HowToReach = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  
    return (
        <div className='container mb-5'>
            <h1 className='my-4 text-center'>HOW TO REACH</h1>
            <hr className='my-5' />

            <h2 className='text-center'>BY ROAD</h2>
            <table className="table table-success table-striped">
                <thead className='h-4'>
                    <tr>
                        <th className='col-md-4' scope="col">NAME OF THE CITY</th>
                        <th className='col-md-4' scope="col">DURATION OF TRAVEL</th>
                        <th className='col-md-4' scope="col">KILOMETER</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Sirsi</td>
                        <td>44 Min</td>
                        <td>27 Km</td>
                    </tr>
                    <tr>
                        <td>Siddapur</td>
                        <td>57 Min</td>
                        <td>37.3 Km</td>
                    </tr>
                    <tr>
                        <td>Yellapur</td>
                        <td>1Hr 46 Min</td>
                        <td>77 Km</td>
                    </tr>
                    <tr>
                        <td>Sagar</td>
                        <td>1Hr 41 Min</td>
                        <td>71.6 Km</td>
                    </tr>
                </tbody>
            </table>
            <br /><br /><br />


            <h2 className='text-center'>BY RAIL</h2>
            <table className="table table-success table-striped">
                <thead>
                    <tr>
                        <th className='col-md-4' scope="col">NAME OF THE RAILWAY STATION</th>
                        <th className='col-md-4' scope="col">DURATION OF TRAVEL</th>
                        <th className='col-md-4' scope="col">KILOMETER</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Kumta Railway Station ( KT )</td>
                        <td>01h 50 Min</td>
                        <td>64.7 Km</td>
                    </tr>
                    <tr>
                        <td>Talguppa Railway Station ( TLGP )	</td>
                        <td>01h 18 Min</td>
                        <td>54.6 Km</td>
                    </tr>
                    <tr>
                        <td>Haveri Railway Station ( HVR )	</td>
                        <td>02h 22 Min</td>
                        <td>101 Km</td>
                    </tr>
                    <tr>
                        <td>Hubballi Railway Station ( UBL )</td>
                        <td>03h 03 Min</td>
                        <td>129 Km</td>
                    </tr>
                    <tr>
                        <td>Mangalore Railway Station ( MAJN )</td>
                        <td>05h 35 Min</td>
                        <td>259 Km</td>
                    </tr>
                </tbody>
            </table>
            <br /><br /><br />
            <h2 className='text-center'>BY AIR</h2>
            <table className="table table-success table-striped">
                <thead>
                    <tr>
                        <th className='col-md-4' scope="col">NAME OF THE AIRPORT</th>
                        <th className='col-md-4' scope="col">DURATION OF TRAVEL</th>
                        <th className='col-md-4' scope="col">KILOMETER</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Hubballi Airport ( HBX )</td>
                        <td>03h 08 Min</td>
                        <td>141 Km</td>
                    </tr>
                    <tr>
                        <td>Dabolim Airport Goa ( GOI )</td>
                        <td>05h 02 Min</td>
                        <td>211 Km</td>
                    </tr>
                    <tr>
                        <td>Mangalore International Airport ( IXE )</td>
                        <td>05h 40 Min</td>
                        <td>262 Km</td>
                    </tr>

                </tbody>
            </table>
        </div>
    )
}

export default HowToReach