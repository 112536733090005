import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../images/home/1.jpg'
import Img2 from '../images/home/2.jpg'
import Img3 from '../images/home/3.jpg'
import Img4 from '../images/home/4.jpg'
import Img6 from '../images/home/6.jpg'
import Img7 from '../images/home/7.jpg'
import room1 from '../images/rooms/room1.1.jpg'
import room2 from '../images/rooms/room2.1.jpg'
import room3 from '../images/rooms/room3.2.jpg'
import room4 from '../images/rooms/room4.2.jpg'
import about1 from '../images/home/about1.jpg'
import about2 from '../images/home/about2.jpg'
import food1 from '../images/foods/1.jpg'
import food2 from '../images/foods/2.jpg'
import food3 from '../images/foods/3.jpg'
import food4 from '../images/foods/4.jpg'
import unchalliFalls from '../images/scenic/unchalliFalls.jpg'
import bheemangudda from '../images/scenic/bheemangudda.jpg'
import manjuguniTemple from '../images/scenic/manjuguniTemple.jpg'
import jogfalls from '../images/scenic/jog-falls.jpg'
import farming from '../images/activities/farming.jpg'
import swimming from '../images/activities/swimming.jpg'
import views from '../images/activities/views.jpeg'
import trekking from '../images/activities/Trekking.jpg'



const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='carousel-container'>
            {/* front view */}
            <div id="myCarousel" className="carousel slide mb-5 carousel-container" data-bs-ride="carousel">
                <div className="carousel-inner">

                    <div className="carousel-item active">
                        <img src={Img1} className="d-block w-100  cover" alt="Home with hospitality" />
                        <div className="carousel-caption  d-md-block">
                            <h1 className='text-align-left'>Feel the harmony<br />
                                at Pragati Homestay <br />
                                in Sirsi!</h1>
                            <p>Explore the natural beauty of Malnad, Western ghats, Uttara Kannada.</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={Img2} className="d-block w-100  cover" alt="Home with hospitality" />
                        <div className="carousel-caption d-md-block">
                            <h1>Feel the harmony at<br />
                                Pragati Homestay <br />
                                in Sirsi!</h1>
                            <p>Explore the natural beauty of Malnad, Western ghats, Uttara Kannada.</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={Img3} className="d-block w-100  cover" alt="Registration homestay certificate" />
                        <div className="carousel-caption d-md-block">
                            <h1>Feel the harmony at<br />
                                Pragati Homestay <br />
                                in Sirsi!</h1>
                            <p>Explore the natural beauty of Malnad, Western ghats, Uttara Kannada.</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={Img4} className="d-block w-100  cover" alt="nice side view of home" />
                        <div className="carousel-caption d-md-block">
                            <h1>Feel the harmony at<br />
                                Pragati Homestay <br />
                                in Sirsi!</h1>
                            <p>Explore the natural beauty of Malnad, Western ghats, Uttara Kannada.</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={Img7} className="d-block w-100  cover" alt="nice side view of home" />
                        <div className="carousel-caption d-md-block">
                            <h1>Feel the harmony at<br />
                                Pragati Homestay <br />
                                in Sirsi!</h1>
                            <p>Explore the natural beauty of Malnad, Western ghats, Uttara Kannada.</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={Img6} className="d-block w-100  cover" alt="nice side view of home" />
                        <div className="carousel-caption d-md-block">
                            <h1>Feel the harmony at<br />
                                Pragati Homestay <br />
                                in Sirsi!</h1>
                            <p>Explore the natural beauty of Malnad, Western ghats, Uttara Kannada.</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={about1} className="d-block w-100  cover" alt="nice side view of home" />
                        <div className="carousel-caption d-md-block">
                            <h1>Feel the harmony at<br />
                                Pragati Homestay <br />
                                in Sirsi!</h1>
                            <p>Explore the natural beauty of Malnad, Western ghats, Uttara Kannada.</p>
                        </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            {/* Rooms */}
            <div className="container mt-5">
                <div className="card my-3 border-0 shadow-lg bg-white mb-5 p-3 rounded">
                    <div className="row g-0">
                        <div className="col-md-8">
                            <div id="carouselRoom" className="carousel slide" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselRoom" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselRoom" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselRoom" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselRoom" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={room1} className="d-block w-100" alt="hospitality rooms" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={room2} className="d-block w-100" alt="confortable beds" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={room3} className="d-block w-100" alt="wide space rooms" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={room4} className="d-block w-100" alt="cleanly maintained bathrooms" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselRoom" ine data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselRoom" ine data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="card-body ">
                                <h3 className="card-title greenText">Our Rooms</h3>
                                <p className='card-text greenText'>Facilities</p>
                                <ul className="card-text">
                                    <li>Bathroom Essentials</li>
                                    <li>Bedroom Comforts</li>
                                    <li>Breakfast , Lunch , Dinner</li>
                                    <li>Tea/Coffee , Snacks</li>
                                    <li>Car Parking space</li>
                                    <li>High-speed Wifi connection</li>
                                </ul>
                               
                                <Link to='/rooms'>
                                    <button className='btn btn-success rounded my-auto'>Book Now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* about */}
            <div className="container mt-5">
                <div className='shadow-lg bg-white mb-5 p-3 rounded'>
                <h2 className='text-center greenText'>Live With the Nature</h2>
                <h5 className="text-center greenText">Explore the natural beauty of Malnad, Western ghats, Uttara Kannada.</h5>
                <p>Pragati Homestay offers the unexplored beauty of nature with the traditional hospitality of Malnad (malenādu). Surrounded by thick forest and various other natural treasures which have become part of local life since generations, Pragati Home Stay is a perfect destination for creating a fun-filled and joyous memory in your life.</p>
                <div className="grid">
                    <div className="card g-col-12 g-col-md-6 border-0 shadow-lg bg-white mb-5  rounded">
                        <img src={about1} className="-top" alt="Homestay side view" />
                    </div>
                    <div className="card g-col-12 g-col-md-6 border-0 shadow-lg bg-white mb-5  rounded">
                        <img src={about2} className="-top" alt="Homestay side view" />
                    </div>
                </div>
                </div>
            </div>

            {/* scenic trails */}
            <div className="container mt-5">
                <div className="card my-3 border-0 shadow-lg bg-white mb-5 p-3 rounded">
                    <div className="row g-0">
                        <div className="col-md-4 ">
                            <div className="card-body ">
                                <h3 className="card-title greenText">SCENIC TRAILS</h3>
                                <p className='card-text'>We “Pragati Homestay” heartily invite you to visit our holiday home to experience the serenity of Nature with a Peaceful home to take an actual break from all your work and visit an overwhelmingly beautiful region with abundant Natural resources everywhere.</p>
                                <Link to='/scenictrail'>
                                    <button className='btn btn-success rounded my-auto'>View more</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div id="carouselScenic" className="carousel slide" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselScenic" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselScenic" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselScenic" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselScenic" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={jogfalls} className="d-block w-100" alt="Jog Falls" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={bheemangudda} className="d-block w-100" alt=" view point of Bheemanvare Gudda" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={unchalliFalls} className="d-block w-100" alt="Unchalli Falls" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={manjuguniTemple} className="d-block w-100" alt="Manjguni Temple" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselScenic" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselScenic" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* cuisine */}
            <div className="container mt-5">
                <div className="card my-3 border-0 shadow-lg bg-white mb-5 p-3 rounded">
                    <div className="row g-0">
                        <div className="col-md-8">
                            <div id="carouselCuisine" className="carousel slide" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselCuisine" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselCuisine" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselCuisine" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselCuisine" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={food1} className="d-block w-100" alt="delicious food" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={food2} className="d-block w-100" alt="heatlthy food" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={food3} className="d-block w-100" alt="homemade food" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={food4} className="d-block w-100" alt="tasty food" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselCuisine" ine data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselCuisine" ine data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="card-body ">
                                <h3 className="card-title greenText">Our Restaurant</h3>
                                <p className='card-text greenText'>Delicious Malnad vegetarian lunch/dinner</p>
                                <p className='card-text greenText'>Breakfast/Snacks</p>
                                <p className='card-text'>Enjoy the mouth-watering traditional Havyaka food, it's another opportunity to explore the tastes of Malenadu.</p>
                                <Link to='/cuisine'>
                                    <button className='btn btn-success rounded my-auto'>View more</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* activities */}
            <div className="container mt-5">
                <div className="card my-3 border-0 shadow-lg bg-white mb-5 p-3 rounded">
                    <div className="row g-0">
                        <div className="col-md-4 ">
                            <div className="card-body ">
                                <h3 className="card-title greenText">Activities</h3>
                                <p className='card-text'>Pragati Homestay is a newly set up all-in-one homestay where you can enjoy your time to the fullest. It is our commitment to provide you with the best every time. Setup is made in such a way so as to make you at ease and comfortable. We offer a host of recreational facilities. Facilities are designed to add more fun and joy to your stay with us.</p>
                                <Link to='/activities'>
                                    <button className='btn btn-success rounded my-auto'>View more</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div id="carouselActivity" className="carousel slide" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselActivity" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselActivity" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselActivity" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={views} className="d-block w-100" alt="beautiful view of nature" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Enjoy the Views</h5>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={swimming} className="d-block w-100" alt="Enjoying swimming in river" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Enjoy Fresh Water Swim</h5>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={trekking} className="d-block w-100" alt="Trekking in beautiful jungle" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Go for a Jungle Trek</h5>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <img src={farming} className="d-block w-100" alt="Farming" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Observe the Farming</h5>
                                        </div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselActivity" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselActivity" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home