import React, { useEffect } from 'react'


const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='container mb-5'>
            <h1 className='my-4 text-center'>Terms and Conditions</h1>
            <hr className='my-5' />
            <p> Please read all terms and conditions carefully before using this site. By visiting the website and availing any services through our site, you are bound to accept all terms and conditions mentioned here.</p>
            <ol>
                <li>By making the reservation, you agree that you have read, understood and accepted the terms and conditions.</li>
                <li>You warrant that you are 18 years of age or over and have legal capacity to use this site.</li>
                <li>You warrant that all details submitted during booking procedure is correct and you accept for financial responsibility for every transactions made under your name or account.</li>
                <li>Reservation is complete only after the payment process and you will receive a confirmation message.</li>
                <li>You hereby authorize Kodai Resort Hotel to process all your information provided and the persons named on the booking which is obtained in connection with your booking.</li>
                <li>The check in and check out time is 10:00 am. Early check-ins and late check-outs are subject to availability.</li>
                <li>We have the right to change the terms and conditions at any time without any notice and you agree that your use on this website will be conditional upon the terms and conditions in force.</li>
                <li>Your use on the website for any information or services is entirely at your own risk, for which we shall not be liable.</li>
                <li>Unauthorized use of our website may give rise to claim for damage or criminal offence.</li>
                <li>Our site may include links to other websites for your convenience to provide further information. It doesnâ€™t mean that we endorse the website(s). Also we are not responsible for the content in the linked website(s).</li>
                <li>Any dispute arising out of your use of this website is subject to laws of India.</li>
                <li>If you have any suggestions or enquiry, please do not hesitate to contact us.</li>
            </ol>

            <h3>Booking Procedure</h3>
            <ol>
                <li>Make all your reservations through our booking page and fill up the form completely with relevant and accurate information.</li>
                <li>You will receive a confirmation on room availability and invoice by mail with appropriate link to our payment gateway.</li>
                <li>You may make the payment by clicking the link of payment gateway, which will take you to a secured web page.</li>
                <li>The payment acceptance receipt will be available online. Take a print out of the conformation and make sure every detail is correct.</li>
                <li>For any change or enquiries regarding booking please contact us through e-mail.</li>
                <li>Accommodation cost includes the following:-</li>
                <ol>
                    <li>Rate includes the specific type of room for specified number of days. The food is included as per the chosen plan.</li>
                    <li>Taxes in relation to room charges.</li>
                    <li>Extra bed will be provided only if specified.</li>
                </ol>
            </ol>
            
            <h3> Payment Policy </h3>
            <ol>
                <li>As per the policy referred in the booking page, full or 50% of the total amount will be deducted from your credit card at the time of booking.</li>
                <li>Amount once paid is non-refundable.</li>
                <li>Payment transactions are encrypted by a secure payment system endorsed by a leading bank.</li>
                <li>Payment can also be done via Visa & Master Card.</li>
                <li>If you make payment using credit card of a different person, you agree that you have legal authorization from the credit card holder to make reservations on his behalf.</li>
            </ol>
        </div>
    )
}

export default Terms