import React , {useEffect} from 'react'

const Booking = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='container my-5'>
      <h3>Online Booking is currently unavailable please conatact us for reservations.</h3>
    </div>
  )
}

export default Booking