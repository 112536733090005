import React from 'react'
import { useRef } from "react";
import { Link } from "react-router-dom";
import Logo from '../images/logo.png'

const Navbar = () => {
    const navButton = useRef(null);
    const linksContainerRef = useRef(null);
    function collapseNav() {
        navButton.current.classList.add("collapsed");
        linksContainerRef.current.classList.remove("show");
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
            <div className="container-fluid">
                {/* navbar barnd */}
                <Link onClick={collapseNav} className="navbar-brand" to="/">
                    <img src={Logo} alt="prgatihomestay logo" style={{ "height": "30%", "width": "50%" }} />
                </Link>

                {/* navbar toggler for small screens */}
                <button ref={navButton} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* navbar contents */}
                <div ref={linksContainerRef} className="collapse navbar-collapse " id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item mx-2">
                            <Link onClick={collapseNav} className="nav-link link-success" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item mx-2">
                            <Link onClick={collapseNav} className="nav-link link-success" to="/rooms">Rooms</Link>
                        </li>
                        <li className="nav-item mx-2">
                            <Link onClick={collapseNav} className="nav-link link-success" to="/cuisine">Cuisine</Link>
                        </li>
                        <li className="nav-item mx-2">
                            <Link onClick={collapseNav} className="nav-link link-success" to="/scenictrail">Scenic Trails</Link>
                        </li>
                        <li className="nav-item mx-2">
                            <Link onClick={collapseNav} className="nav-link link-success" to="/activities">Activities</Link>
                        </li>
                        <li className="nav-item mx-2">
                            <Link onClick={collapseNav} className="nav-link link-success" to="/gallery">Gallery</Link>
                        </li>
                        <li className="nav-item mx-2">
                            <Link onClick={collapseNav} className="nav-link link-success" to="/reviews">Reviews</Link>
                        </li>
                        <li className="nav-item mx-2">
                            <Link onClick={collapseNav} className="nav-link link-success" to="/howtoreach">How To Reach</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar