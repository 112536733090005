import React , {useEffect} from 'react'
import Img1 from '../images/foods/dose.jpg'
import Img2 from '../images/foods/kotteidli.jpg'
import Img3 from '../images/foods/kadbu.jpg'
import Img4 from '../images/foods/redrice.jpg'
import Img5 from '../images/foods/tambuli.jpg'
import Img6 from '../images/foods/appehuli.jpg'
import Img7 from '../images/foods/gadigemosaru.jpg'
import Img8 from '../images/foods/habbada-adige.jpg'
import Img11 from '../images/foods/snacks.jpg'
import Img9 from '../images/foods/cuisine-1.jpg'
import Img10 from '../images/foods/wood.jpg'

const Cuisine = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='container mb-5'>
            <h1 className='my-4 text-center'>CUISINE</h1>
            <hr className='my-5' />

            {/* main article for cuisine page */}
            <div className="card mb-3 border-0">
                <div className="row g-0">
                    <div className="col-md-6">
                        <img src={Img9} className="img-fluid rounded-start" alt="Yummy food" />
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="card-body ">
                            <p className="card-text">
                                When compared to the rest of Karnataka, Uttarakannada's cuisine is somewhat diverse. Seafood is highly popular in the coastal districts, which are the most inhabited, and there are also a large number of vegetarians. Food habits vary by community, however rice is the most common staple. The major breakfast dishes include banana buns, kottu, neer dose, and uppittu. Vegetable huli,tambuli, Hashi, Sasime, and Appehuli are popular selections for lunch and dinner, served with rice. Jaggery, rather than sugar, is used to make the unique celebration sweet dishes. Tea is the most popular beverage, which is frequently complemented by delectable Happalas, bonda, and oggaraneavalakki..
                            </p>
                            <p className='my-5'> <b>We Offer only vegetarian foods</b></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card text-bg-dark border-0 mt-5">
                <img src={Img10} className="card-img woodImageHeight" alt="bg-wood" />
                <div className="card-img-overlay">
                    <h4 className="card-title mt-5 mb-3 text-center">The Uttara Kannada people's food is closely linked to nature and the four seasons.</h4>
                    <ul>
                        <li><p className="card-text my-2">Coconut, rice are the main food of Uttara Kannada people.</p></li>
                        <li><p className="card-text my-2">Tender leaves, roots, seeds, nuts, and fruits which are available naturally are used extensively for cooking in the district.</p></li>
                        <li><p className="card-text my-2">Jaggery, Ghee, butter milk are also an important ingredients used in everyday cooking.</p></li>
                        <li><p className="card-text my-2">Aromatic,Appemidi, a wild mango variety available only in the Aghanashini river basin is used for making mouthwatering pickle.</p></li>
                    </ul>
                </div>
            </div>

            {/* foods gallery */}
            <div className="container grid my-5">
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img1} className="card-img-top" alt="Tasty Dose" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Dose</h5>
                        </div>
                    </div>
                </div>
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img2} className="card-img-top" alt="smooth Kotte Idli" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Kotte Idli</h5>
                        </div>
                    </div>
                </div>
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img3} className="card-img-top" alt="delicious food kadbu" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Kadbu</h5>
                        </div>
                    </div>
                </div>
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img4} className="card-img-top" alt="Nicely cooked Red Rice" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Red Rice</h5>
                        </div>
                    </div>
                </div>
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img5} className="card-img-top" alt="Tambuli" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Tambuli</h5>
                        </div>
                    </div>
                </div>
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img6} className="card-img-top" alt="Appehuli" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Appehuli</h5>
                        </div>
                    </div>
                </div>
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img7} className="card-img-top" alt="healthy food Gadige Mosaru" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Gadige Mosaru</h5>
                        </div>
                    </div>
                </div>
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img8} className="card-img-top" alt="All varities of tasty food" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Habbadoota</h5>
                        </div>
                    </div>
                </div>
                <div className="g-col-md-4 g-col-6">
                    <div className="card border-0">
                        <img src={Img11} className="card-img-top" alt="hot and spicy snacks" />
                        <div className="card-body">
                            <h5 className="card-text text-center">Snacks</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cuisine