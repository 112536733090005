import React , {useEffect} from 'react'

const Policy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='container mb-5'>
            <h1 className='my-4 text-center'>Cancellation Policy</h1>
            <hr className='my-5' />

            <h4> Returns</h4>
            <p>
                We at Pragati Homestay have a generous cancellation policy. Under this policy:-
            </p>
            <ol>
                <li>If cancellation is done 10 days prior to the date of arrival, full amount will be refunded.</li>
                <li>For cancellation done 6 days prior to the date of arrival, 50% of the total amount will be refunded. After that no refund will be made.</li>
                <li>If you do not turn up (no show) at the arrival date, no refund will be made.</li>
                <li>Refund of amount will be made through bank transfer due to security reasons.</li>
                <li>Acknowledgment of receipt of cancellation can be obtained from our office.</li>
            </ol>

            <h4>Refunds (if applicable) </h4>

           <p>If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</p> 

            <h4>Late or missing refunds (if applicable)</h4>

            <p>If you haven't received a refund yet, first check your bank account again.</p>

            <p>Then contact your credit card company, it may take some time before your refund is officially posted.</p>

            <p>Next, contact your bank. There is often some processing time before a refund is posted.</p>

            <p>If you've done all of this and you still have not received your refund yet, please email us</p>

            <p>at <a className='link-success noUnderline' href="mailto:pragatihomestay@gmail.com">pragatihomestay@gmail.com</a> or write to</p>

            <p><b> Nagarakodi,Shamemane Via Tangarmane,</b></p>

            <p><b>
                Po: Balesara, Siddapura Taluq, Uttara Kannada, Karnataka.</b></p>
        </div>
    )
}

export default Policy