import React, { useState, useEffect } from 'react'
import axios from 'axios'

const Reviews = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    const [inputs, setInputs] = useState({});
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        showReviews();
    }, []);

    // function to fetch reviews from database
    const showReviews = () => {
        axios.get('http://pragatihomestay.in/api/getReviews.php').then(function (response) {
            setReviews(response.data);
        })
    }
    // function to recored the data being entered
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    // clear input feilds after submission
    const clearInputs = () => {
        document.getElementById("reviewForm").reset();
    }
    // function to submit review to the database
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post("http://pragatihomestay.in/api/reviewSubmit.php", inputs).then(function (response) {
            showReviews();
        })
        clearInputs();
    }

    return (
        <div className='container mb-5'>
            <h4 className='my-4'>Write a Review</h4>
            {/* form to write review */}
            <form id="reviewForm" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="Name" className="form-label">Name</label>
                    <input type="text" className="form-control" onChange={handleChange} name="user_name" placeholder="Enter your Name" />
                </div>
                <div className="mb-3">
                    <label htmlFor="Email" className="form-label">Email address</label>
                    <input type="email" className="form-control" onChange={handleChange} name="user_email" placeholder="Enter Your Email" />
                </div>
                <div className="mb-3">
                    <label htmlFor="Review" className="form-label">Review</label>
                    <textarea className="form-control" onChange={handleChange} name="user_review" rows="3"></textarea>
                </div>
                <button type='submit' className='btn btn-outline-success'>Submit</button>
            </form>

            {/* display all reviews */}
            <div className="ontainer my-3">
                <h3 className='text-center'>Reviews</h3>
                <hr />
                <div className="container grid my-5">
                    {reviews.map((review, key) =>
                        <div key={key} className="g-col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-text">{review.user_name} </h5>
                                    <h6 className='text-muted card-text'>{review.updated_at}</h6>
                                    <p className='card-text my-3'>{review.user_review}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Reviews