import React , {useEffect} from 'react'
import nature from '../images/activities/nature.jpg'
import farming from '../images/activities/farming.jpg'
import swimming from '../images/activities/swimming.jpg'
import sports from '../images/activities/sports.jpg'
import views from '../images/activities/views.jpeg'
import trekking from '../images/activities/Trekking.jpg'

const Activities = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="container mb-5">
            <h1 className='my-4 text-center'>ACTIVITIES</h1>
            <p >Pragati Homestay is a newly set up all in one homestay where you can enjoy your time to the fullest. It is our commitment to provide you with the best every time. Setup is made in such a way so as to make you at ease and comfortable. We offer a host of recreational facilities. Facilities are designed to add more fun and joy when you stay with us</p>
            <hr className='mt-5' />

            <h4 className='text-center'>Top-rated Activities</h4>
            <p className='text-center'>Take a closer look at our activities and decide which one you want to experience next.</p>
            <div className="grid">
                <div className="g-col-md-6 g-col-12 my-3">
                    <div className="hover hover-3 text-white rounded"><img src={nature} alt="Enjoying the Nature view" />
                        <div className="hover-overlay"></div>
                        <div className="hover-3-content px-5 py-4">
                            <h3 className="hover-3-title  font-weight-bold mb-1"><span className="font-weight-light">Relax with </span>Nature</h3>
                            <p className="hover-3-description small mb-0 activityFont" >Exploring treasures of jungles is one of the most sought after activities that will give us a good physical exercise as well as fun. You can go for a short walk through jungle or choose to go for a longer trek visiting treasures of forest. Trek can be as long as 5-10 kilometers. You can ask for a guide who will be of assistance during your trek.</p>
                        </div>
                    </div>
                </div>

                <div className="g-col-md-6 g-col-12 my-3">
                    <div className="hover hover-3 text-white rounded card border-0 ">
                        <img className='card-img' src={swimming} alt="Swimming in a fresh water" />
                        <div className="hover-overlay"></div>
                        <div className="hover-3-content px-5 py-4">
                            <h3 className="hover-3-title font-weight-bold mb-1"><span className="font-weight-light">Enjoy Fresh </span>Water Swim</h3>
                            <p className="hover-3-description small mb-0 activityFont" >Playing with water is a relaxing and fun filled activity. We have a fresh water flow at our backyard, you can have a swim in flowing river.Ayurveda is an ancient science for natural healing and longevity. At Pragati Homestay you can have complete body rejuvenation with specialized ayurvedic practitioners. Our specialized packages give a complete cleansing to mind as well as body.</p>
                        </div>

                    </div>
                </div>

                <div className="g-col-md-6 g-col-12 my-3">
                    <div className="hover hover-3 text-white rounded"><img src={farming} alt="The farming land" />
                        <div className="hover-overlay"></div>
                        <div className="hover-3-content px-5 py-4">
                            <h3 className="hover-3-title  font-weight-bold mb-1"><span className="font-weight-light">Observe the </span>Farming</h3>
                            <p className="hover-3-description small mb-0 activityFont">Farming activities of Malnad are quite diversified and produce a significant agriculture output. We have an Arecanut garden with various other crops such as cordomom and banana at our back yard. You can observe various farming activities such as sowing, planting, weeding, harvesting and so on depending on the season of your visit. Best part is you can be a part of such activities to have a hands on experience.</p>
                        </div>
                    </div>
                </div>

                <div className="g-col-md-6 g-col-12 my-3">
                    <div className="hover hover-3 text-white rounded"><img src={trekking} alt="Jungle trekking" />
                        <div className="hover-overlay"></div>
                        <div className="hover-3-content px-5 py-4">
                            <h3 className="hover-3-title  font-weight-bold mb-1"><span className="font-weight-light">Go for a </span>Jungle Trek</h3>
                            <p className="hover-3-description small mb-0 activityFont">Exploring treasures of jungles is one of the most sought after activities that will give us a good physical exercise as well as fun. You can go for a short walk through jungle or choose to go for a longer trek visiting treasures of forest. Trek can be as long as 5-10 kilometers. You can ask for a guide who will be of assistance during your trek.</p>
                        </div>
                    </div>
                </div>

                <div className="g-col-md-6 g-col-12 my-3">
                    <div className="hover hover-3 text-white rounded card border-0 ">
                        <img className='card-img' src={sports} alt="Enjoying Games" />
                        <div className="hover-overlay"></div>
                        <div className="hover-3-content px-5 py-4">
                            <h3 className="hover-3-title font-weight-bold mb-1"><span className="font-weight-light">Engage in </span>Sports</h3>
                            <p className="hover-3-description small mb-0 activityFont">Engaging in sports is an exciting activity that adds up to your joy. We have a new volleyball court and a badminton court coming up. Anytime you can grab a bat and ball to go play cricket. Apart from out-door games you can engage yourself in in-door games such as chess. Sitting around Camp-fire at night and having a chat will surely add up to your joy. At Pragati Homestay, you can have a Camp-fire, spend some quality time with family and friends.</p>
                        </div>

                    </div>
                </div>

                <div className="g-col-md-6 g-col-12 my-3">
                    <div className="hover hover-3 text-white rounded card border-0 ">
                        <img className='card-img' src={views} alt="beautiful view of nature" />
                        <div className="hover-overlay"></div>
                        <div className="hover-3-content px-5 py-4">
                            <h3 className="hover-3-title font-weight-bold mb-1"><span className="font-weight-light">Enjoy the </span>Views</h3>
                            <p className="hover-3-description small mb-0 activityFont">Malnad area has its special identity for birds. Just jump in to forest, you can see countless number of different birds. Sometimes even localities might find a new bird which was never before observed. Nature photography is an exciting activity among photography enthusiasts. The joy of capturing a winter Cobweb, mist filled trees, sun who is just blinking at the world, full moon hidden behind those trees can never be expressed in words, it has to be experienced.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activities