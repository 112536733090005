import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/Home';
import Cuisine from './components/Cuisine';
import HowToReach from './components/HowToReach';
import ScenicTrails from './components/ScenicTrails';
import Activities from './components/Activities';
import AboutUs from './components/AboutUs';
import Gallery from './components/Gallery';
import Rooms from './components/Rooms';
import Booking from './components/Booking';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Policy from './components/Policy';
// import Login from './components/Admin/Login';
import FrontEnd from './components/Frontend/FrontEnd';
import Reviews from './components/Reviews';

function App() {
  return (
    <div>

      <Routes>
        <Route path="/" element={<FrontEnd />}>
        <Route index element={<Home />} />
          <Route path="/cuisine" element={<Cuisine />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/howtoreach" element={<HowToReach />} />
          <Route path="/scenictrail" element={<ScenicTrails />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/policy" element={<Policy />} />
        </Route>
        {/* <Route path="/login" element={<Login />} /> */}
      </Routes>

    </div>


  );
}

export default App;
