import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../images/rooms/room1.1.jpg'
import Img2 from '../images/rooms/room1.2.jpg'
import Img3 from '../images/rooms/room1.3.jpg'
import Img4 from '../images/rooms/room1.4.jpg'
import Img5 from '../images/rooms/room2.1.jpg'
import Img6 from '../images/rooms/room2.2.jpg'
import Img7 from '../images/rooms/room2.3.jpg'
import Img9 from '../images/rooms/room3.1.jpg'
import Img10 from '../images/rooms/room3.2.jpg'
import Img11 from '../images/rooms/room3.3.jpg'
import Img13 from '../images/rooms/room4.1.jpg'
import Img14 from '../images/rooms/room4.2.jpg'



const Rooms = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className='container mb-5'>
            <h1 className='my-4 text-center'>ACCOMODATION</h1>
            <p className="text-center">At Pragati Homestay,we would like to offer best in the class facilities at affordable price.</p>
            <hr className='my-5' />

            {/* display rooms */}
            <div className="card mb-3 border-0 mb-5 shadow-lg p-3 mb-5 bg-white rounded" >
                <div className="row g-0">
                    <div className="col-md-8">
                        <div id="carousel1" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={Img1} className="d-block w-100" alt="Good hospatality rooms" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img2} className="d-block w-100" alt="Comfortable beds" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img3} className="d-block w-100" alt="big space rooms" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img4} className="d-block w-100" alt="cleanly maintained bathrooms" />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carousel1" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carousel1" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card-body">
                            <h3 className="card-title text-center">Cottage-1</h3>
                            <hr />
                            <ul className="card-text">
                                <li>Bathroom Essentials</li>
                                <li>Bedroom Comforts</li>
                                <li>Breakfast , Lunch , Dinner</li>
                                <li>Tea/Coffee , Snacks</li>
                                <li>Car Parking space</li>
                                <li>High-speed Wifi connection</li>
                            </ul>
                            <p className='card-text mx-3'> <small>Prices starts at:</small> <br /><b>1500</b> <small>INR/person </small></p>
                            <div className="my-3">
                                <button type="button" class="btn btn-outline-success mx-3" data-bs-toggle="modal" data-bs-target="#priceModal">
                                    Price Details
                                </button>
                                <div class="modal fade" id="priceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">Price Details</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p> <b>750</b> <small>INR/person </small> if the age is between 5 to 12</p>
                                                <p> <b>1500</b> <small>INR/person </small> if the age is above 12</p>
                                                <p> <b>No charges</b>  if the age is below 5 </p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3">
                                <Link to='/booking'>
                                    <button className='btn btn-outline-success mx-3'> Book Rooms</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="card mb-3 border-0 mb-5 shadow-lg p-3 mb-5 bg-white rounded" >
                <div className="row g-0">
                    <div className="col-md-8">
                        <div id="carousel2" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={Img5} className="d-block w-100" alt="Good hospatality rooms" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img6} className="d-block w-100" alt="Comfortable beds" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img7} className="d-block w-100" alt="big space rooms" />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carousel2" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carousel2" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card-body">
                            <h3 className="card-title text-center">Cottage-2</h3>
                            <hr />
                            <ul className="card-text">
                                <li>Bathroom Essentials</li>
                                <li>Bedroom Comforts</li>
                                <li>Breakfast , Lunch , Dinner</li>
                                <li>Car Parking space</li>
                                <li>High-speed Wifi connection</li>
                            </ul>
                            <p className='card-text mx-3'> <small>Prices starts at:</small> <br /><b>1500</b> <small>INR/person </small></p>
                            <div className="my-3">
                                <button type="button" class="btn btn-outline-success mx-3" data-bs-toggle="modal" data-bs-target="#priceModal">
                                    Price Details
                                </button>
                                <div class="modal fade" id="priceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">Price Details</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p> <b>750</b> <small>INR/person </small> if the age is between 5 to 12</p>
                                                <p> <b>1500</b> <small>INR/person </small> if the age is above 12</p>
                                                <p> <b>No chares</b>  if the age is below 5 </p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3">
                                <Link to='/booking'>
                                    <button className='btn btn-outline-success mx-3'> Book Rooms</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3 border-0 mb-5 shadow-lg p-3 mb-5 bg-white rounded" >
                <div className="row g-0">
                    <div className="col-md-8">
                        <div id="carousel3" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={Img9} className="d-block w-100 card-img" alt="Good hospatality rooms" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img10} className="d-block w-100 card-img" alt="Comfortable beds" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img11} className="d-block w-100 card-img" alt="big space rooms" />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carousel3" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carousel3" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card-body">
                            <h3 className="card-title text-center">Cottage-3</h3>
                            <hr />
                            <ul className="card-text">
                                <li>Bathroom Essentials</li>
                                <li>Bedroom Comforts</li>
                                <li>Breakfast , Lunch , Dinner</li>
                                <li>Car Parking space</li>
                                <li>High-speed Wifi connection</li>
                            </ul>
                            <p className='card-text mx-3'> <small>Prices starts at:</small> <br /><b>1500</b> <small>INR/person </small></p>
                            <div className="my-3">
                                <button type="button" class="btn btn-outline-success mx-3" data-bs-toggle="modal" data-bs-target="#priceModal">
                                    Price Details
                                </button>
                                <div class="modal fade" id="priceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">Price Details</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p> <b>750</b> <small>INR/person </small> if the age is between 5 to 12</p>
                                                <p> <b>1500</b> <small>INR/person </small> if the age is above 12</p>
                                                <p> <b>No chares</b>  if the age is below 5 </p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3">
                                <Link to='/booking'>
                                    <button className='btn btn-outline-success mx-3'> Book Rooms</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3 border-0 mb-5 shadow-lg p-3 mb-5 bg-white rounded" >
                <div className="row g-0">
                    <div className="col-md-8">
                        <div id="carousel4" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={Img13} className="d-block w-100" alt="Good hospatality rooms" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img14} className="d-block w-100" alt="Comfortable beds" />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carousel4" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carousel4" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card-body">
                            <h3 className="card-title text-center">Cottage-4</h3>
                            <hr />
                            <ul className="card-text">
                                <li>Bathroom Essentials</li>
                                <li>Bedroom Comforts</li>
                                <li>Breakfast , Lunch , Dinner</li>
                                <li>Car Parking space</li>
                                <li>High-speed Wifi connection</li>
                            </ul>
                            <p className='card-text mx-3'> <small>Prices starts at:</small> <br /><b>1500</b> <small>INR/person </small></p>
                            <div className="my-3">
                                <button type="button" class="btn btn-outline-success mx-3" data-bs-toggle="modal" data-bs-target="#priceModal">
                                    Price Details
                                </button>
                                <div class="modal fade" id="priceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">Price Details</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p> <b>750</b> <small>INR/person </small> if the age is between 5 to 12</p>
                                                <p> <b>1500</b> <small>INR/person </small> if the age is above 12</p>
                                                <p> <b>No chares</b>  if the age is below 5 </p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3">
                                <Link to='/booking'>
                                    <button className='btn btn-outline-success mx-3'> Book Rooms</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3 border-0 mb-5 shadow-lg p-3 mb-5 bg-white rounded" >
                <div className="row g-0">
                    <div className="col-md-8">
                        <div id="carousel5" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={Img1} className="d-block w-100" alt="Good hospatality rooms" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img2} className="d-block w-100" alt="Comfortable beds" />
                                </div>
                                <div className="carousel-item">
                                    <img src={Img3} className="d-block w-100" alt="big space rooms" />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carousel5" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carousel5" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card-body">
                            <h3 className="card-title text-center">Cottage-5</h3>
                            <hr />
                            <ul className="card-text">
                                <li>Bathroom Essentials</li>
                                <li>Bedroom Comforts</li>
                                <li>Breakfast , Lunch , Dinner</li>
                                <li>Car Parking space</li>
                                <li>High-speed Wifi connection</li>
                            </ul>
                            <p className='card-text mx-3'> <small>Prices starts at:</small> <br /><b>1500</b> <small>INR/person </small></p>
                            <div className="my-3">
                                <button type="button" class="btn btn-outline-success mx-3" data-bs-toggle="modal" data-bs-target="#priceModal">
                                    Price Details
                                </button>
                                <div class="modal fade" id="priceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">Price Details</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p> <b>750</b> <small>INR/person </small> if the age is between 5 to 12</p>
                                                <p> <b>1500</b> <small>INR/person </small> if the age is above 12</p>
                                                <p> <b>No chares</b>  if the age is below 5 </p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3">
                                <Link to='/booking'>
                                    <button className='btn btn-outline-success mx-3'> Book Rooms</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rooms;
