import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div>
            <hr className='my-5' />
            <div className="container">
                <div className="grid">
                    <div className="g-col-md-4 g-col-12">
                        <h6> <b>Explore</b> </h6>
                        <ul className="list-unstyled">
                            <li className='my-3'><Link className='footerStyle' to='/aboutus'>About us</Link></li>
                            <li className='my-3'><Link className='footerStyle' to='/terms'>Terms & Conditions</Link></li>
                            <li className='my-3'><Link className='footerStyle' to='/policy'>Cancellation Policy</Link></li>
                            <li className='my-3'><Link className='footerStyle' to='/privacy'>Privacy Statement</Link></li>
                            {/* <li className='my-3'><Link className='footerStyle' to='/login'>Admin Panel</Link></li> */}
                        </ul>
                    </div>
                    <div className="g-col-md-4 g-col-12">
                        <h6><b>Contact Us</b></h6>
                        <ul className="list-unstyled">
                            <li className='grid mt-3 mb-1'>
                                <span className="material-symbols-outlined g-col-1">
                                    pin_drop
                                </span>
                                <p className='footerStyle g-col-11'>Nagarakodi,Shamemane Via Tangarmane,<br /> Po: Balesara, Siddapura Taluq,<br /> Uttara Kannada, Karnataka.</p>
                            </li>
                            <li className='grid mb-3'>
                                <span className="material-symbols-outlined g-col-1">
                                    alt_route
                                </span>
                                <a className='link-success noUnderline g-col-11 ' href="https://goo.gl/maps/419e6RJ5Aoe4DDmG6">Direction</a>
                            </li>
                            <li className='grid'>
                                <span className="material-symbols-outlined g-col-1">
                                    call
                                </span>
                                <p className='footerStyle g-col-11'>08389-201402 <br /> 9482551872 - WhatsApp <br />8296591735</p>
                            </li>
                            <li className='grid'>
                                <span className="material-symbols-outlined g-col-1">
                                    mail
                                </span>
                                <a className='link-success noUnderline g-col-11' href="mailto:pragatihomestay@gmail.com">pragatihomestay@gmail.com</a>
                            </li>
                        </ul>
                    </div>
                    <div className="g-col-md-4 g-col-12 ">
                        <h6><b>Visit Our Page</b></h6>

                        <h6 className='grid'>
                            <a href="https://www.facebook.com/pragatihomestay/" className='link-success noUnderline'>Facebook</a>
                            <a href="https://www.instagram.com" className='link-success noUnderline'>Instagram</a>
                            {/* eslint-disable-next-line */}
                            <a target="_blank" rel="noopener" href="https://www.tripadvisor.in/Hotel_Review-g2294723-d3869514-Reviews-Pragati_Home_Stay-Sirsi_Uttara_Kannada_District_Karnataka.html" className='link-success noUnderline'>Tripadvisor</a>
                        </h6>
                    </div>
                </div>
            </div>
            <hr />
            <div className="container">
                <p className='footerStyle'>Pragatihomestay © 2022 All Rights Reserved</p>
            </div>
        </div>
    )
}

export default Footer