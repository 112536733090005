import React, {useEffect} from 'react'
import unchalliFalls from '../images/scenic/unchalliFalls.jpg'
import bheemangudda from '../images/scenic/bheemangudda.jpg'
import manjuguniTemple from '../images/scenic/manjuguniTemple.jpg'
import marikamba from '../images/scenic/marikamba.jpg'
import jogfalls from '../images/scenic/jog-falls.jpg'
import yana from '../images/scenic/Yana.jpg'


const ScenicTrails = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='container mb-5'>
            <h1 className='my-4 text-center'>SCENIC TRAILS</h1>
            <hr className='my-5' />

            <div className="card my-5 border-0 shadow-lg p-3 mb-5 bg-white rounded">
                <div className="row g-0">
                    <div className="col-md-7">
                        <img src={unchalliFalls} className="img-fluid rounded-start" alt="Unchalli Falls" />
                    </div>
                    <div className="col-md-5 d-flex align-items-center">
                        <div className="card-body ">
                            <h3 className="card-title text-center">Unchalli Falls</h3>
                            <p className="card-text">Unchalli Falls, also known as Lushington Falls, is a 116-meter plunge in the Aghanashini river that creates a waterfall. The falls are in the Uttara Kannada District of Karnataka, India, near Siddapura.</p>
                            <p className="card-text"><small className="text-muted">18 Kms away from the homestay.</small></p>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="card my-5 border-0 shadow-lg p-3 mb-5 bg-white rounded">
                <div className="row g-0">
                    <div className="col-md-5 d-flex align-items-center">
                        <div className="card-body ">
                            <h3 className="card-title text-center">Bheemanavare gudda</h3>
                            <p className="card-text">Bheemanavare Gudda is a hill, from which we can see sunset and Arabbi sea.</p>
                            <p className="card-text"><small className="text-muted">22 Kms away from the homestay.</small></p>

                        </div>
                    </div>
                    <div className="col-md-7">
                        <img src={bheemangudda} className="img-fluid rounded-start" alt="Bheemanvare Gudda" />
                    </div>
                </div>
            </div>

            <div className="card my-5 border-0 shadow-lg p-3 mb-5 bg-white rounded">
                <div className="row g-0">
                    <div className="col-md-7">
                        <img src={manjuguniTemple} className="img-fluid rounded-start" alt="Manjguni Temple" />
                    </div>
                    <div className="col-md-5 d-flex align-items-center">
                        <div className="card-body">
                            <h3 className="card-title text-center">Manjuguni Temple</h3>
                            <p className="card-text">Manjuguni Sri Venkateshwara temple is a famous hindu temple. people believes that this god is having the powe same as “Thirupathi God”.</p>
                            <p className="card-text"><small className="text-muted">35 Kms away from the homestay.</small></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card my-5 border-0 shadow-lg p-3 mb-5 bg-white rounded">
                <div className="row g-0">
                    <div className="col-md-5 d-flex align-items-center">
                        <div className="card-body ">
                            <h3 className="card-title text-center">Marikamaba Temple Sirsi</h3>
                            <p className="card-text">The Temple is known as among the most powerful peethas. Fanatics are drawn here by the beautiful woodwork, the hill, and the old Kaavi art. Visitors are comforted by the sight of Marikamba wearing a diamond-encrusted crown. It is a shrine dedicated to music, literature, and the arts. The Devi fair, which takes place every other year, is regarded as one of the state's major events.</p>
                            <p className="card-text"><small className="text-muted">27 Kms away from the homestay.</small></p>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <img src={marikamba} className="img-fluid rounded-start " alt="Marikamba temple" />
                    </div>
                </div>
            </div>

            <div className="card my-5 border-0 shadow-lg p-3 mb-5 bg-white rounded">
                <div className="row g-0">
                    <div className="col-md-5 d-flex align-items-center">
                        <div className="card-body ">
                            <h3 className="card-title text-center">YANA</h3>
                            <p className="card-text">Do you want to go on an adventure tour? Trekking across the mountainous jungle of Sahyadri to the black rock formations may be a rewarding experience. During the rainy season, the path is wet and slick, while trekking in the winter may be a breeze. Yana is ideal for nature photography; enthusiasts can take a few pictures of the surrounding beauties. This biodiversity hotspot is home to a variety of endangered bird and butterfly species; bird watching in this region may be a wonderful experience.</p>
                            <p className="card-text"><small className="text-muted">57 Kms away from the homestay.</small></p>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <img src={yana} className="img-fluid rounded-start" alt="Yana" />
                    </div>
                </div>
            </div>

            <div className="card my-5 border-0 shadow-lg p-3 mb-5 bg-white rounded">
                <div className="row g-0">
                    <div className="col-md-7">
                        <img src={jogfalls} className="img-fluid rounded-start" alt="Jog Falls" />
                    </div>
                    <div className="col-md-5 d-flex align-items-center">
                        <div className="card-body ">
                            <h3 className="card-title text-center">JOG Falls</h3>
                            <p className="card-text">The wondrous sight is that of river Sharavathi taking a spectacular leap into the chasm from a height of 830 feet to from the highest waterfalls in India. The river Sharavathi hurtles in 4 distinct cascades. The first of these falls is known as the 'Raja' - the grandest, an unbroken sheet of water, the Raja plunges into the vapor shrouded poll 900 feet below. A few 100 feet way down, with a thunderous gush, Raja is joined by 'Roarer'.</p>
                            <p className="card-text"><small className="text-muted">62 Kms away from the homestay.</small></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ScenicTrails